import * as React from "react";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { EMBEDDABLES_ONBOARDING_FLOW_ID } from "../../../layouts/scripts";

export default () => {
  return (
    <BasicLayout
      noIndex
      noFollow
      runEmbeddedablesScript
      embeddedablesFlowId={EMBEDDABLES_ONBOARDING_FLOW_ID}
    >
      <div />
    </BasicLayout>
  );
};
